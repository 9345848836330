import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";

const initState = {
  products: []
};

const productReducer = (initialProducts) => (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload
    };
  }

  if (state.products.length === 0) {
    return {
      products: initialProducts
    }
  }

  return state;
};

export default productReducer;
