import {
  ADD_MESSAGE, CHANGE_CHAT, CLEAR_CHAT, SHOW_CHAT, SET_TYPING
} from "../actions/chatActions";
import {v4 as uuidv4} from 'uuid';
import {LOGIN} from "../actions/authActions";

const chatTypes =  'LuxChat' | 'LuxAppointment' | 'LuxShipping';
const initState = {
  messages: [],
  chatVisible: false,
  chatTitle: 'Finance ChatBot',
  type: 'Finance_en',
  isAgentTyping: false,
  lang: 'en',
  threadId: uuidv4(),
  deviceId: uuidv4(),
};

const chatReducer = (state = initState, action) => {
  const payload = action.payload;

  if (action.type === LOGIN) {
    console.log('LOGIN THERE', state);
    return {
      ...state,
      threadId: payload.sub
    }
  }

  if (action.type === ADD_MESSAGE) {
    return {
      ...state,
      messages: [
        ...state.messages,
        {
          me: payload.me,
          payload: payload.payload
        }
      ]
    }
  }
  if (action.type === SHOW_CHAT) {
    return {
      ...state,
      chatVisible: payload
    }
  }

  if (action.type === CLEAR_CHAT) {
    return {
      ...state,
      messages: []
    }
  }

  if (action.type === CHANGE_CHAT) {
    return {
      ...state,
      type: payload.type,
      chatTitle: payload.title,
      lang: payload.lang
    }
  }

  if (action.type === SET_TYPING) {
    return {
      ...state,
      isAgentTyping: payload,
    }
  }


  return state;
};

export default chatReducer;
