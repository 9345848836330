import {useEffect, useRef, useState} from "react";
import * as moment from "moment";
import {clearChat} from "../redux/actions/chatActions";
import {useToasts} from "react-toast-notifications";
import {v4} from 'uuid'

const isCommandPayload = (payload) => payload.startsWith("CMD:");
const removeCommandKey = (payload) => payload.slice(4);
const getCommandAndKey = (payload) => ({
  command: removeCommandKey(payload).split('=')[0],
  key: removeCommandKey(payload).split('=')[1]
})

export function useIMIChat({
                             addMessage,
                             clearChat,
                             authData,
                             chatData,
                             showChat,
                             addToCart,
                             products,
                             changeChat,
                             setTyping
                           }) {
  //const imiInstanceRef = useRef(null);
  const {addToast} = useToasts();

  const imiInitialized = useRef(false);
  const IMI = window.IMI;

  const sendMessage = async ({
                               payload,
                               extras = null,
                               type = null,
                               display = null,
                               show = true
                             }) => {

    if (
      showChat && addToCart && products && changeChat
    )
    if (isCommandPayload(payload)) {
      const command = getCommandAndKey(payload);
      switch (command.command) {
        case 'CLOSE_CHAT': {
          await disconnect();
          changeChat({title: 'Chat', type: 'LuxChat'});
          await clearChat();
          showChat(false);
          return;
        }
        case 'ADD_TO_CART': {
          const product = products.filter((product) => product.id == command.key)[0];
          addToCart(
            product,
            addToast,
            1,
            product.variation ? product.variation[0].color : "",
            product.variation ? product.variation[0].size[0].name : ""
          );
          break;
        }
      }
    }


    const message = new IMI.ICMessage();
    message.setMessage(payload);
    const thread = new IMI.ICThread();
    thread.setId(chatData.threadId);
    thread.setTitle(type ?? chatData.type);
    thread.setStreamName(chatData.streamName);
    message.setThread(thread);


    thread.setExtras(extras ?? {
      "customerID": authData.sub,
      "mobile": authData.phone,
      "email": authData.email
    });
    /*
    message.setCustomTags({
      userSub
    });
     */
    const messaging = IMI.ICMessaging.getInstance();
    messaging.publishMessage(message, {
      onSuccess: function () {
        //console.log("MESSAGE SENT");

        if (show) {
          addMessage({
            me: true,
            payload: {
              text: payload,
              display: display ?? payload,
              dateTime: moment().format('hh:mm')
            },
          })
        }
      },
      onFailure: function (e) {
        console.error('ERROR: SEND MESSAGE FAILED', e);
      }
    });

  }

  const onMessageReceived = (message) => {
    if (message.outgoing) {
      //console.log('MESSAGE SENDED', message);
      return;
    }
    console.log('MESSAGE RECEIVED', message);
    let messageReceived = message.message;
    try {
      messageReceived = JSON.parse(messageReceived);
      
      messageReceived.map(_message => addMessage({
        me: false,
        payload: {
          ..._message,
          dateTime: moment().format('hh:mm')
        }
      }) 
      )
    } catch (e) {
      console.log('MESSAGE NOT JSON FORMAT');
      if (messageReceived !== '$$$$$TYPING$$$$$')
        addMessage({
          me: false,
          payload: {
            text: messageReceived,
            dateTime: moment().format('hh:mm')
          }
        })
        else {
          setTyping(true);
          setTimeout(() => setTyping(false), 2000)
        }
    }
  }

  const disconnect = () => {
    return new Promise(resolve => {
      const messaging = IMI.ICMessaging.getInstance();
      messaging.disconnect();
      //console.log('isReg1', IMI.IMIconnect.isRegistered());
      IMI.IMIconnect.unregister({
        onSuccess: function (msg) {
          //console.log("de register...");
          //console.log('isReg2', IMI.IMIconnect.isRegistered());
          IMI.IMIconnect.shutdown();
          resolve(true)
        },
        onFailure: function (err) {
          //console.log("failed to un register");
          //console.log('isReg3', IMI.IMIconnect.isRegistered());
          if (err.code === 6006) {
            IMI.IMIconnect.shutdown();
            resolve(true);
          }
          throw err
        }
      });
      imiInitialized.current = false;
    })
  }

  const refresh = async () => {
    //console.log('refresh imi')
    try {
      await disconnect();
    } catch (e) {

    }
    clearChat();
    await init(async () => {
      await sendMessage({
        payload: 'START',
        show: false,
      });
    });
  }

  function connect({onFirstConnect}) {
    //console.log('DEBUG', 'CONNECT');
    let messaging = IMI.ICMessaging.getInstance();
    if (!messaging.isConnected()) {
      //console.log('DEBUG', 'NOT CONNECTED');
      try {
        messaging.connect();
        onFirstConnect && onFirstConnect();
      } catch (e) {
        //console.log('DEBUG', 'CONNECT ERROR', e);
      }
    }
  }


  function registerMessageListener() {
    let messaging = IMI.ICMessaging.getInstance();
    messaging.setICMessagingReceiver({
      onConnectionStatusChanged: (statuscode) => {
        //since there can only be one listener, hence dispatching at document level.
        //console.log('statuschanged', statuscode)
      },
      onMessageReceived: onMessageReceived,
    });
    //There can only be one listener.
  }

  async function onRegistration({onFirstConnect}) {
    //console.log('DEBUG', 'ON REGISTRATION');
    // NOTE: Correct order is startup, register, connect, registerListeners
    await connect({onFirstConnect});
    registerMessageListener();

  }

  async function register({userID, onFirstConnect}) {
    //console.log('DEBUG', 'REGISTER');
    if (IMI.IMIconnect.isRegistered()) {
      //console.log('DEBUG', 'ALREADY REGISTERED');
      await onRegistration({onFirstConnect});
      return;
    }
    userID = userID || v4();
    var deviceID = userID + new Date().getTime(); //any random no.
    var deviceProfile = new IMI.ICDeviceProfile(deviceID, userID);
    await new Promise(resolve => {
      var registerCallback = {
        onSuccess: async (msg) => {
          //console.log('DEBUG', 'REGISTER SUCCESS');
          await onRegistration({onFirstConnect});
          resolve(true);
        },
        onFailure: (err) => {
          //console.log('DEBUG', 'REGISTER FAILURE', err);
        },
      };
      IMI.IMIconnect.register(deviceProfile, registerCallback);
    })
  }

  const init = async (onFirstConnect = null) => {
    //console.log('DEBUG', 'INIT');
    IMI.IMIconnect.startup();

    await register({userID: authData.sub, onFirstConnect})
  }
  /*
  useEffect(() => {
    //console.log('init', imiInitialized.current);
    if (!imiInitialized.current) init();

    return () => {
      disconnect()
    }
  }, [])
   */

  return {
    sendMessage,
    refresh,
    init
  }

}
